import React from 'react'
import styled from 'styled-components'
import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem auto 15rem;
  text-align: center;
  color: var(--color-primary);

  h2 {
    font-size: 20rem;
  }

  p {
    font-size: 2.4rem;

    a {
      margin-left: 1rem;
    }
  }
`

const NotFound = ({ location }) => {
  const memberPage = location.pathname.includes('member')
  return (
    <PageContent>
      <Seo title='404' />
      <Wrapper>
        <h2>404 test</h2>
        <p>找不到您要的資訊</p>
        <p>
          前往
          <a href={memberPage ? '/member' : '/'}>
          {memberPage ? '晶晶團員首頁' : '晶晶首頁'}
          </a>
        </p>
      </Wrapper>
    </PageContent>
  )
}

export default NotFound
